/*remove :root and do the same thing in index.css rename and use that variable name*/

:root {
    --primary-color: #015fb1;
    --hover-color: #0056b3;
    --text-color: #333;
    --detail-color: #555;
    --accent-color: #4d954c;
    --background-color: #f5f5f5;
  }
  .background{
    
    height: 90%;
    /* margin-top: 20px; */
  } 
  .container {
    margin: 3.5rem  auto 2rem;
    background-color: var(--background-color);
    width: 40rem;
    /* max-width: 1200px; */
    padding: 0 2em;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }
  .content {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--text-color);
    text-align: justify;
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: 50vh;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
  .buttonContainer{
    display: flex;
    justify-content: space-between;
  }
/* 
  @media (min-width: 40rem) {
    .container{
      max-width: 40px;
    }
  }
   */