.eventCard {
  margin: 10px;
  width: 210px;
  height: 250px;
  border: 2px solid var(--color2);
  background-color: white;
  border-radius: 12px;
  overflow: hidden;
  transition: 1s ease-in-out;
}

.eventCard:hover {
  box-shadow: 0px 5px 5px 0px var(--color5);
}

.img {
  height: 65%;
  overflow: hidden;
  object-fit: fill;
}

.desc {
  font-size: 12px;
  color: var(--color4);
  margin-left: 8px;
  margin-right: 8px;
  width: 200px;
  overflow: hidden;
  height: 40px;
  /* border: 1px solid red; */
}

.title {
  color: var(--color1);
  font-size: 14px;
  font-weight: 800;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 5px;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* border: 1px solid red; */
}

.action {
  text-decoration: none;
  color: var(--color2);
  font-size: 12px;
  margin-left: 5px;
  margin-right: 5px;
  float: right;
  /* border: 1px solid red; */
}
