/* CSS for About Us Section */
* {
  box-sizing: border-box;
}

.aboutUs {
  margin: 0;
  /* padding: 5rem 0 0; */
}

.textInfo {
  text-align: center;
  padding: 0 15%;
  margin-bottom: 5%;
}
.aboutBanner{
  padding: 7rem 1rem 2rem;
  /* background: linear-gradient(45deg, #bff098, #6fd6ff); */
  /* background-image: url(../assets//images//bsbeBuilding.jpg); */
  background-image: url(../assets//images/aboutus-banner.png);
  background-size: cover;
  display: flex;
  justify-content: center;
}
.aboutBanner .head{
  text-align: center;
  /* background-color: #fdfdfb98; */
  /* padding: 2rem 0rem; */
  color: white;
}
.head h1{
  color: white;
  margin-bottom: 0;
}
.head p{
  font-size: var(--fontSize5);
  margin-top: 0;
}
.textInfo h1 {
  font-size: 2.2rem;
  color: var(--color1);
  font-weight: 600;
  margin-bottom: 1%;
  margin-top: 3%;
}

.textInfo p {
  font-size: 1rem;
  color: var(--color4);
  font-weight: 400;
  margin-top: 1%;
  margin-bottom: 3%;
  text-align: left;
}
.textInfo b {
  color: var(--color2);
}

.aboutUs hr {
  border-style: none;
  border-top-style: dotted;
  height: 0px;
  border-color: grey;
  border-width: 5px;
  width: 50px;
}

.founders {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  background-size: cover;
  background-position: top;
  width: 80%;
  color: #fefcf3;
  margin: 5% auto;
  border-radius: 20px;
}

.founders h1 {
  font-size: 2.2em;
  font-weight: 600;
  padding-top: 2%;
  text-align: center;
}

.founders img {
  width: 50%;
}

.cardContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 30px;
  padding-bottom: 20px;
}

.card {
  text-align: center;
  width: 230px;
  margin-bottom: 40px;
}

.card h3 {
  margin: 0;
  font-weight: 600;
}

.card p {
  font-weight: 500;
  margin: 0;
}

@media (width<900px) {
  .card {
    width: 160px;
    margin-bottom: 40px;
  }
}

@media (width<768px) {
  .card {
    width: 120px;
    margin-bottom: 40px;
  }
  .intro b{
    text-align: start;
  }
}

.readOrHide {
  font-size: 1.1rem;
  color: var(--color1);
  cursor: pointer;
}
@media only screen and (max-width:480px){
 .textInfo{
  padding: 0 10px;
 } 
}