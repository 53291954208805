/* CollaborationsPage.css */

.collaborations-page-container {
  max-width: 70%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: white;
  /* border: 2px solid #4d954c; */
  padding-top: 11rem;
}

.page-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #015fb1;
}

.section-heading {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.existing-collaborations,
.collaborate-with-us,
.contact-us {
  margin-bottom: 30px;
}

.collaborations-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.collaboration-item {
  width: 48%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border: 2px solid #4d954c;
  border-radius: 8px;
  overflow: hidden;
}

.collaboration-image {
  width: 200px;
  height: 150px;
  object-fit: cover;
}

.collaboration-details {
  padding: 15px;
  flex: 1;
}

.company-name {
  font-size: 1.5em;
}

.target-audience,
.prerequisites {
  margin: 10px 0;
}

.collaboration-form {
  max-width: 400px;
  margin: 0 auto;
}

.form-label {
  font-size: 1.2em;
  margin: 10px 0;
  display: block;
  color: #015fb1;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0 15px;
  border: 2px solid #4d954c;
  border-radius: 4px;
  font-size: 1em;
}

.form-button {
  background-color: #015fb1;
  color: white;
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.form-button:hover {
  background-color: #013567;
}

.contact-info {
  margin: 10px 0;
}
@media only screen and  (max-width:768px){

}