.notfoundpage {
    max-width: 1200px;
    margin: 0 auto;
    padding: 5rem;
    background-color: white;
    text-align: center;
} 
.navigate{
  display: flex;
  flex-direction: row;
  gap: 1em;
}
.back{
  font-size: 15px;
  padding: 9px 12px;
  border-radius: 2px;
  background-color: #4d9454;
  color: white;
  border: none;
  cursor: pointer;  
} 
 .back:hover {
  background-color: #55ac5e;
} 
 .contact{
  font-size: 16px;
  padding: 7px 19.2px;
  border-radius: 2px;
  background: #015aa9;
  color: white;
  border: none;
  cursor: pointer;
} 
