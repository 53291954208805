.blog-overview-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}

.blog-card {
  width: calc(50% - 10px);
  background-color: #ffffff;
  border: 1px solid #4d954c;
  border-radius: 8px;
  overflow: hidden;
  
   
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 4px 4px 6px rgba(77, 149, 76, 0.4);
}

.blog-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.blog-content {
  padding: 15px;
}

.blog-date {
  font-size: 14px;
  color: #555;
}

.blog-title {
  font-size: 18px;
  font-weight: bold;
  color: #004aad; 
  margin: 10px 0;
}

.blog-description {
  font-size: 16px;
  color: #000000;
}

@media (max-width: 768px) {
  .blog-card {
    width: 100%;
  }
}
