/* Gallery.css */

.gallery-container {
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 2rem .3em; */
  /* background-color: white; */
  /* border: 2px solid #4d954c; */
  background-color: #015fb1 ;
}

.gallery-heading {
  font-size: 2.5em;
  color: #015fb1;
  margin-bottom: 20px;
  text-align: center;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  background-color: #015fb1 ;
  border: 0px 1px solid #015fb1 ;
  padding: 1rem;
}
@media screen and (max-width:768px) {
  .gallery-container{
    padding-top:5em ;
  } 
}