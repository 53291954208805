.container{
    display: flex;
    flex-direction: row;
    margin: 0px 2rem;
    justify-content: center;
}
.subheading{
    text-align: start;
}
.main{
    background-color: #eceaea34;
    padding: 0em 1em;
}
.student{
    width: 75vw;
    position: relative;
  
    margin: 0px auto;
    /* text-align: center; */
  }
.faculty {
    width: 75vw;
    position: relative;
  
    margin: 50px auto;
    /* text-align: center; */
  }
.studentContainer, .facultyContainer{
    display: flex;
    width: 100%;
    overflow: scroll;
    scroll-behavior: smooth;
    margin: 30px auto 0;
    gap: 1.25em;
}

.studentContainer::-webkit-scrollbar, .facultyContainer::-webkit-scrollbar {
    width: 0;
  }
  .sliderRight {
    position: absolute;
    right: 0px;
    top: calc(50% - 30px);
  
    font-size: 45px;
  
    color:#4d9454;
    cursor: pointer;
  }
  .sliderLeft {
    position: absolute;
    left: 0px;
    top: calc(50% - 30px);
  
    font-size: 45px;
  
    color:#4d9454;
    cursor: pointer;
  }
  .studentContainer:hover .sliderLeft, .facultyContainer:hover .sliderLeft {
    color: rgba(255, 255, 255, 0.9);
  }
  .studentContainer:hover .sliderRight, .facultyContainer:hover .sliderRight {
    color: rgba(255, 255, 255, 0.9);
  }
  .sidebar{
    /* margin-left:2rem ; */
    display: flex;
    flex-direction:column;
    gap: .5em;
    background-color: #dfdfdf5b;
    padding: 2em 1em 1em;
    width: auto;
  }
  .subheadicon{
    width: 25px;
    height: 25px;
    color: var(--color2);
}
@media only screen and (max-width:1148px){
    .container{
        flex-direction: column;
    }
    .sidebar{
        flex-direction: row;
        justify-content: center;
    }
}