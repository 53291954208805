/* Our Team CSS Part */
.ourTeam {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin: 5px;
  }
  .ourTeam .banner{
    background-image:url("../assets//images//allTeamImg.jpg");
    width: 100vw;
    height:400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    align-items:end;
    position: relative;
  }
  .banner h1 {
    font-size: 2.5em;
    color: #015fb1;
    -webkit-text-stroke: 3px white; /*to outline border of letter*/
    padding-bottom:2em;
    /* margin-left: 5px; */
    text-align: center;
  }
  .allTeam{
    /* float: left; */
     /*this will float the child containe left wrt parent container*/
    padding-left:1px 5px ;
    width: 100%;
  }
  .allTeam button{
    border-style: none;
    cursor: pointer;
    background-color: none;
    padding: .5em;
    margin: 0;
  }
  .allTeam button:hover{
    background-color: none;
  }
  .allTeam li{
    padding: 0;
  }
  .allTeam ul{
    display: flex;
    align-items: center;
    flex-direction: row;
    list-style: none;
    gap: .5em;
    padding-left: 0px;
  }
  .activeLink{
    color: var(--color2);
    border-bottom: 3px solid yellowgreen;
  }
  .notActiveLink{
    color:var(--color1);
  }
  .overallCoordinator h2{
    padding-top: 0px;
    margin-top: 0;
  }
  .overallCordiProfile,
  .cordiProfile,
  .secyProfile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* justify-content: space-between; */
  }
  .cordiProfile,.secyProfile {
    margin: 0px 120px;
  }
  .overallCoordinator h2,
  .coOrdinator h2,
  .secretary h2 {
    color: #015fb1;
    border-radius: 1px;
    font-size: 2em;
  }
  .coOrdinator,
  .secretary{
    padding-top: 2.8em;
  }
  @media only screen and (max-width:480px) {
    .allTeam{
      display: flex;
      justify-content: center;
    }
  }