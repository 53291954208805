:root {
    --text-color: #333;
    --detail-color: #555;
    --accent-color: #4d954c;
    --background-color: #f5f5f5;
  }
.title {
    font-size: 2.5rem;
    color: var(--text-color);
    text-align: left;
  }
  .detail {
    display: flex;
    justify-content:flex-start;
    gap: 1em;
    margin-bottom: 15px;
    color: var(--detail-color);
  }
  .detail p {
    color: var(--accent-color);
    font-size: larger;
  }