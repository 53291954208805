.dropdown-menu {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  list-style: none;
  text-align: center;
  background: var(--color1);
  padding: 0;
}

.dropdown-menu li {
  width: 100%;
  cursor: pointer;
}

.dropdown-menu li a {
  width: 100%;
}

.dropdown-menu li:hover {
  background: #5cabff;
}

.dropdown-menu.clicked {
  display: none;
}

/* .dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
} */

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
