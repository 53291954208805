/* AlumniNetworkPage.css */

.alumni-network-page {
  text-align: center;
  background-color: white;
  padding: 20px;
  margin-top: 10rem;
}

h1,
h2,
h3 {
  color: #015fb1;
}

h2.sub-heading {
  color: #4d954c;
}

.core-alumni-container,
.non-core-alumni-container {
  margin: 20px;
}

.alumni-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.alumni-card {
  border: 2px solid #4d954c;
  margin: 10px;
  padding: 20px;
  max-width: 300px;
}

.alumni-card img {
  width: 100%;
  height: auto;
  border: 1px solid #4d954c;
  border-radius: 8px;
}

/* Add any additional styles as needed */
