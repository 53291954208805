/* ProfOfferProject.css */

.prof-offer-project-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: white;
  padding-top: 11rem;
}

.prof-offer-project-heading {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.prof-offer-project-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-label {
  font-size: 1.2em;
  margin: 10px 0;
  display: block;
}

.form-input,
.form-textarea {
  width: 100%;
  padding: 10px;
  margin: 5px 0 15px;
  border: 2px solid #4d954c;
  border-radius: 4px;
  font-size: 1em;
}

.form-button {
  background-color: #015fb1;
  color: white;
  padding: 10px 20px;
  font-size: 1.2em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.form-button:hover {
  background-color: #013567;
}
