.content{
    margin: 5%;
    border-radius: 10px;
    padding: 15px;
    box-sizing: border-box;
    
}
.info{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.instructions{
    flex: 1;
}
.boxes{
    display: flex;
    gap: 40px;
}
.brochure{
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.brochure img{
    max-width: 100%;
    max-height: 100%;
    display: block;
}

@media (max-width : 760px){
    .info{
        align-items: flex-start;
        flex-direction: column;
    }
    .instructions{
        margin-right: 0;
        margin-bottom: 10px;
    }
    .brochure{
        width: 100%;
    }
}