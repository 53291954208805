/* StudentAchievementsPage.css */

/* .achievements-page {
  text-align: center;
  padding-top: 13em;
}

.achievements-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.achievement-card {
  display: flex;
  flex-direction:row;
  border: 2px solid #4d954c;
  margin: 10px;
  padding: 20px;
  max-width: 50em;
  transition: transform 0.3s;
}
.achievement-img{

}
.achievement-detail{

}
.achievement-card:hover {
  transform: scale(1.05);
}

.achievement-card a {
  text-decoration: none;
  color: inherit;
}

.achievement-img img{
  width: 100%;
  height: auto;
  border: 1px solid #4d954c;
  border-radius: 8px;
} */

/* Add any additional styles as needed */
.achievementsPage {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2em 0em;
  margin:0em 2em;
}
/* .allContainer{
  display: flex;
  flex-direction: row;
} */
/* .containerLeft{
  height: 100%;
} */
.mainContentCard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: nowrap;
}
/* .containerRight{
  height: 100%;
} */
.card {
  display: flex;
  flex-direction: row;
  border: 2px solid #4d954c;
  border-radius: 10px;
  max-width: 800px;
}
.achievementImg {
  width: 50%;
  border-radius: 9px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.achievementImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.achievementImg:hover{
  transform: scale(1.05);
}
.detail {
  width: 50%;
  padding: 20px;
}
.hrline{
  border-top: 1px solid rgb(167, 162, 162) ;
  width: auto;
}
.detail h2{
  /* max-height: 60px; */
  color:var(--color3);
}
.detail p{
  min-height: 100px;
  color: black;
  text-align: start;
}
.detail h4{
  /* max-height: 30px; */
  font-weight:bold;
  margin: 0;
}
@media screen and (max-width:930px) {
  .achievementsPage{
    padding-top: 3.5em;
  }
}
@media only screen and (max-width: 768px) {
  .card {
    max-width: auto;
    display: flex;
    flex-direction: column;
  }
  .achievementImg{
    height: 60%;
    width: auto;
  }
  .detail{
    height: 40%;
    width: auto;
  }
}
