/* ProfAchievements.css */

.prof-achievements-container {
  text-align: center;
}
.prof-achievements-banner{
  padding: 7rem 1rem 2rem;
  background: linear-gradient(45deg, #bff098, #6fd6ff);
}
.prof-achievements-heading {
  font-size: 2.5em;
  margin-bottom: 20px;
}
.prof-achievements-All{
  max-width: 800px;
  
  margin: 0 auto;
  padding: 20px;
}
.prof-achievements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.prof-achievement-item {
  border: 2px solid #4d954c;
  height: fit-content;
  border-radius: 8px;
  overflow: hidden;
}

.prof-achievement-image {
  width: 100%;
  height: 300px;
  display: block;
}

.prof-achievement-details {
  padding: 15px;
}

.prof-name {
  font-size: 1.5em;
}

.prof-achievement {
  color: rgb(83, 83, 83);
}
