.applybutton {
  justify-content: center;
}

.background {
  /* margin-top: 75px; */
  height: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-top: 9rem; */
}

.head {
  color: var(--color1);
  margin: 0px;
  margin-top: 20px;
  font-weight: 900;
  padding: 0px 0px 10px 20px;
  /* text-decoration: underline; */
}

.titles {
  text-align: center;
  color: var(--color2);
  margin: 10px;
  padding-left: 20px;
}

.cards {
  display: flex;
  justify-content: left;
  padding: 10px 20px 10px 30px;
  flex-wrap: wrap;
}

.banner {
  width: 100%;
  height: 300px;
  float: left;
}

.indvcard {
  padding: 0px 10px 0px 10px;
}
