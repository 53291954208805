.ourStudents{
    margin-top: 0px;
    padding-top: 0px;
}
.banner{
    background-image: url(../assets/lab//student-banner.jpg);
    text-align: center;
    width: 100vw;
    height: 400px;
    background-size: cover;
    object-fit:cover;
    position: relative;
    top:0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
 .banner h1{
    text-align: center;
    background-color: #fdfdfb5d;
    font-size: xxx-large;
    color: var(--color3);
    padding: 1em 2em;
}
.subHeadings{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5em;
    text-align: center;
    padding: 2em 0em;
}
.subHeading{
    transition: transform .2s;
}
.subHeadings p{
    font-weight: bold;
}
.subHeading:hover{
    transform:scale(1.2);
}
.subheadicon{
    width: 50px;
    height: 50px;
    color: var(--color2);
}
.description{
    text-align: left;
    font-size: large;
}
.content{
    padding: .5em 4em 1em;
}
@media only screen and (max-width:768px){
    .subHeadings{
        flex-direction: column;
    }
    .content{
        padding: .5em 2em 1em;
    }
}
@media  only screen and (max-width:480px) {
    .content{
        padding: .5em 1em 1em;
    }
}