/* FarewellDiaryPage.css */

.farewell-diary-page {
  text-align: center;
  background-color: white;
  padding: 20px;
  padding-top: 11rem;
}

h1,
h2 {
  color: #015fb1;
}

.diary-entries {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: center;
}

.diary-card {
  border: 2px solid #4d954c;
  padding: 20px;
  max-width: 400px;
  margin: auto;
}

.know-more-link {
  display: block;
  color: #015fb1;
  margin-top: 10px;
  text-decoration: none;
}

.know-more-link:hover {
  text-decoration: underline;
}

/* Add any additional styles as needed */
