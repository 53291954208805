.background {
  box-sizing: border-box;
}
.banner {
  padding: 5rem 1rem 2rem;
  background: linear-gradient(45deg, #bff098, #6fd6ff);
}
.subHeadings{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5em;
  text-align: center;
  padding: .5em 0em;
}
.subHeading{
  transition: transform .2s;
}
.subHeadings p{
  font-weight: bold;
}
.subHeading:hover{
  transform:scale(1.2);
}
.subheadicon{
  width: 50px;
  height: 50px;
  color: var(--color2);
}
.banner .titles {
  text-align: center;
  color:var(--color3);
  margin: 0px;
  font-size: 40px;
}
.subTitles{
  position: relative;
  color: #410e07;
  margin-top: 1em;
  border-bottom:5px solid #233d0e ;
  width: 90%;
  margin-left: 15px;
}
.AllContentContainer{
  padding: 1em 4em;
}
.indvcard {
  border: 2px solid var(--color2);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 10px;
}

.compTitle {
  font-size: 18px;
  font-weight: 900;
  margin-bottom: 5px;
  color: var(--color1);
}
.compDesc {
  font-size: 13px;
}
.compLink {
  font-size: 13px;
  color: blue;
}
@media only screen and (max-width:768px){
  .AllContentContainer{
    padding: 1em;
  }
}
.compHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggleIcon {
  cursor: pointer;
  font-size: 24px;
  color: #007bff; 
  border-style: none;
  background-color: none;
}

.compDetails {
  margin-top: 10px;
  
}
