.footer {
  width: 100vw;

/*solved bug part of attaching footer to always bottom, its other part is in app css*/
  margin-top: auto;  
  
  /* border: 1px solid red; */
  /* background: linear-gradient(
    315deg,
    rgba(77, 149, 76, 0.4992121848739496) 15%,
    rgba(1, 95, 177, 0.5020133053221288) 85%
  ); */
  background-color: rgb(16, 16, 49);
  min-height: 250px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color5);
}

.list {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  /* border: 1px solid red; */
}

.left {
  width: 38%;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
  /* border: 1px solid red; */
  min-width: 280px;
  margin: 10px;
}
.center {
  margin: 10px;
  width: 15%;
  font-size: 20px;
  /* border: 1px solid red; */
  min-width: 200px;
  text-align: left;
  text-decoration: none;
  color: var(--color5);
}
.center h4{
  margin: 0;
}
.center ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  text-align:left;
  font-size:1rem;
  font-weight: lighter;
}

.right {
  margin: 10px;
  /* width: 38%; */
  /* min-width: 200px; */
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  font-size: 20px;
  justify-content: center;

  /* border: 1px solid red; */
}

.social {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.social a {
  margin: 5px;
  display: flex;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
}

.social a:hover {
  border-radius: 50%;

  box-shadow: 0px 1px 5px 2px var(--color5);
}

.right input {
  height: 35px;
  width: 100%;
  border: 2px solid var(--color2);
  border-radius: 10px;
  outline: none;
  box-sizing: border-box;
  padding: 5px;
  padding-left: 35px;
  position: absolute;
  margin-right: 10px;
}
@media screen and (max-width: 1100px) {
  .left {
    width: 60%;
  }
  .right {
    width: 60%;
  }
}

@media screen and (max-width: 600px) {
  .left {
    width: 80%;
  }
  .right {
    width: 80%;
  }
  .left{
    flex-direction: column;
  }
  .address{
    padding: 0 2em;
  }
}
