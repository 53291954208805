.app {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* Solved bug code part of always attaching footer to bottom  */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
