.content{
  margin:5vw;
}
.box{
height: 250px;
width:600px;
border:2px solid green;
margin: 5vw;
text-align: center;
font-size:2rem;


}
.boxes{
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
}
@media (max-width: 768px) {
  .box {
    width: 90%;
    margin: 2vw 0; 
  }

  .boxes {
    flex-direction: column; 
    align-items: center; 
  }
}