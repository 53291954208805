.home {
  height: auto;
  box-sizing: border-box;
  position: relative;
  /* perspective: 10px;

  overflow-y: auto;
  overflow-x: hidden; */
}
.videoWrapper {
  position: relative;
  /* padding-bottom: 56.25%;  */
  /* height: 0; */
  /* overflow: hidden; */
  max-width: 100vw;
  /* padding: 0px 10px; */
  /* background: #000;  */
  /* margin-bottom: 20px; */
}
.videoWrapper figcaption{
  text-align: center;
}
/* .videoIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:100%;
  border: 0;
} */

.homeContainer1 {
  position: relative;
  height: 100vh;
  width: 100%;
  background: url(../assets/images/up.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform-style: preserve-3d; */
  /* z-index: -1; */
  /* transform: translateZ(-10px) scale(2); */
}

.homeContainer2 {
  position: relative;
  height: 100vh;
  width: 100%;
  background: url(../assets/images/allTeamImg.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeContainer3 {
  position: relative;
  height: 100vh;
  width: 100%;
  background: url(../assets/images/allCoordieImg.jpg);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeMiddle {
  width: 700px;
  /* height: 30vh; */
  /* margin: 70px auto 20px; */
  padding: 10px;
  /* transform: translateZ(-5px); */
  /* padding-top: 6rem; */
  margin-top: 6rem;
  background-color: #efeeef42;
}

.middleHeader {
  font-size: 10rem;
  /* font-size: 100%; */

  font-family: "poppins", sans-serif;
  height: 120px;
  color: white;
  text-align: center;
  text-shadow: 0 0 100px rgba(0, 0, 0, 0.76);
  margin: 0;
  padding: 0;
}
/* .homeMiddle .middleHeader, .homeMiddle p{
  -webkit-text-stroke: 1px rgb(8, 8, 8);
} */
.middleText {
  color: rgb(255, 255, 255);
  /* font-family: "poppins", sans-serif; */
  font-weight: 600;
  line-height: 1.5rem;

  text-align: center;
  font-size: 2rem;
  text-shadow: 0 0 20px rgb(0, 0, 0, 0.6);
  margin: 0;
  padding-bottom:10px;
}
/* adding stylish quotation mark */
.middleText::after {
  content: '\275E';
  font-size: 1.5em;
  color:#4c924b;
}
.middleText::before{
  content: "\275D";
  font-size: 1.5em;
  color: #015aa9;
}
.homeHeader{
  text-align: center;
}
.aboutContainer {
  width: 75vw;
  margin: 0 auto;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
}

.aboutInfo {
  font-size: 20px;
  text-align: justify;
}

.aboutButton {
  padding: 7px 10px;
  border-radius: 2px;
  background-color: #4d9454;
  color: white;
  border: none;
  cursor: pointer;
}

.aboutButton:hover {
  background-color: #55ac5e;
}

.aboutVideo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutVideo video {
  width: 25vw;
  height: 20vw;
}

@media screen and (max-width: 1300px) {
  .aboutContainer {
    width: 90vw;
    padding-top: 1rem;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 800px) {
  .homeContainer1, .homeContainer2, .homeContainer3{
    height: 400px;
  }
  .aboutContainer {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .aboutInfo {
    font-size: 18px;
  }

  .aboutHeader {
    font-size: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 350px) {
  .aboutVideo video {
    width: 250px;
    height: 150px;
  }

  .aboutInfo {
    font-size: 16px;
  }

  .aboutHeader {
    font-size: 25px;
    text-align: center;
  }
}

.newsCardContainer {
  width: 100%;
  display: flex;
  gap: 20px;
  /* flex-wrap: wrap; */

  margin: 30px auto 0;

  overflow: scroll;
  scroll-behavior: smooth;
}

.newsCardContainer::-webkit-scrollbar {
  width: 0;
}

.newsContainer {
  width: 75vw;
  position: relative;

  margin: 50px auto;
  /* text-align: center; */
}

.newsHeader {
  margin: 0;
}

.upcomingEvents{
  width: 75vw;
  margin: 50px auto;
  background-color: #ffffff;
  padding: 20px;
}

.viewAllBtn {
  padding: 4px 8px;
  font-size: 15px;
  background-color: var(--color1);
  color: white;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}

.viewAllBtn:hover {
  background-color: #0471cf;
}

/* .upcomingEventsHeader, .collaborationHeader, .galleryHeader {
  margin: 0;
} */
.eventsContainer {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 15px auto 0;
}

.message {
  width: 75vw;
  margin: 70px auto;
  display: flex;
  flex-direction: column;
}

.profImg {
  width: 190px;
  height: 230px;
  object-fit: cover;
  border: 1px solid #4d9454;
}

.messageContainer {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.messageCard {
  display: flex;
  gap: 20px;
}

@media (width<= 550px) {
  .messageCard {
    flex-direction: column;
  }
  .profImg {
    width: 200px;
    height: 180px;
  }
}

.profName {
  margin: 0;
}

.messageText {
  text-align: justify;
}

.sliderRight {
  position: absolute;
  right: 0px;
  top: calc(50% - 30px);

  font-size: 45px;

  color: rgba(255, 255, 255, 0.6);
}
.sliderLeft {
  position: absolute;
  left: 0px;
  top: calc(50% - 30px);

  font-size: 45px;

  color: rgba(255, 255, 255, 0.6);
}

.newsContainer:hover .sliderLeft {
  color: rgba(255, 255, 255, 0.9);
}
.newsContainer:hover .sliderRight {
  color: rgba(255, 255, 255, 0.9);
}

/* .newsContainer #sliderLeft {
 
  left: 0px;
}

.newsContainer #sliderRight {
 
  right: 0px;
} */

.messageCard {
  background-color: #efeeef;
  padding: 20px;
  font-size: 18px;
}

.headerIcon {
  color: #0471cf;
  padding-left: 0px;

  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* font-size: 25px; */
  /* padding-top: 10px; */
}
.galleryList{
  margin-top: .3em;
  /* display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 40em; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  /* background-color: #015fb1 ; */
  /* border: 0px 1px solid #015fb1 ; */
}
.collaboration, .galleryContainer{
  width: 75vw;
  margin: 50px auto;
  background-color:none;
  /* padding: 20px; */
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}
.scrollAnimation{
  padding-top: 15px;
  /* display: inline-block; */
  white-space:nowrap;
  animation: scrollAnimation 25s linear infinite;
  display: flex;
  align-items: center;
}
.partnerlogo{
  display: inline-block;
  margin: 0 10px;
  max-width: 20vw;
}
@keyframes scrollAnimation{
  0%{
    transform:translateX(0%);
  }
  100%{
    transform:translateX(-200%);
  }
}

@media screen and (max-width: 800px) {
  .homeMiddle {
    width: 70%;
  }

  .middleHeader {
    font-size: 8rem;
    height: 160px;
  }

  .middleText {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  .homeBottom {
    height: 40vh;
  }
}

@media screen and (max-width: 650px) {
  .homeMiddle {
    width: 80%;
  }

  .middleHeader {
    font-size: 7rem;
    height: 140px;
  }
}
@media screen and (max-width: 480px) {
  .homeMiddle {
    width: 90%;
  }
  .middleHeader {
    font-size: 5rem;
    height: 100px;
  }

  .middleText {
    font-size: 1rem;
    line-height: 1rem;
  }
  .aboutContainer{
  gap: 0;
}
.videoIframe{
  display: block;
   padding-left: 0;
  margin-left: 0;
  padding-bottom: 0px;
  margin-bottom: 0px;
  max-width: 85vw;
}
.videoWrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.collaborationHeader{
  font-size:large;
}
.sections{
  margin: .5em 1em;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.newsContainer, .upcomingEvents, .collaboration, .galleryContainer{
  width: 100%;
  margin: 30px 16px 0px;
}
}

.homeBottom {
  width: 100%;
  height: 35.7vh;
  background-image: linear-gradient(transparent, rgb(255, 255, 255));
}

.homeBottomOver {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30vh;
  background-image: linear-gradient(transparent, rgb(255, 255, 255));
}

.bottomImg {
  position: relative;

  width: 100%;
  height: 60vh;
  background: url(../assets/images/down2.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.upperGradient {
  width: 100%;
  height: 45%;
  background-image: linear-gradient(#F0F0F0, transparent);
}

.upperGradientOver {
  position: absolute;
  top: 0;
  width: 100%;
  height: 45%;
  background-image: linear-gradient(#F0F0F0, transparent);
}
.middleHeader{
  font-size:260%;
  height: 60px;
}
.gene {
  color: var(--color2);
}

.genius {
  color: var(--color1);
}
