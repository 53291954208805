.loginContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding-top: 10rem;
}

.loginCardDown {
  /* display: flex; */
  height: 300px;
  width: 250px;
  background-image: url(../assets/images/logo.png);
  background-position: center;
  /* background-color: aqua; */
  border-radius: 10px;
}

.loginCardUp {
  /* display: flex; */
  height: 300px;
  width: 250px;

  backdrop-filter: blur(4px) saturate(200%);
  -webkit-backdrop-filter: blur(4px) saturate(200%);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  /* border: 1px solid black; */
}
