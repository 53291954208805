/* CSS for Events Section */

* {
  box-sizing: border-box;
}

.eventsContainer {
  margin: 0;
  padding: 1rem 13% 0;
  /* background-color: var(--mainConatinerBackground); */
}

.eventOdd, .eventEven {
  display: flex;
  justify-content: center;
  margin: 5% 0;
  padding: .3em;
  /* background-color: aqua; */
}

.eventEven .txt {
  text-align: left;
  padding-right: 3%;
}
.eventOdd .txt{
  text-align: left;
  padding-left: 3%;
}

.eventEven .imgEven {
  order: 2;
}

.txt h2 {
  color: var(--color1);
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 10px;
  margin-top: 0;
}
.txt p {
  color: var(--color4);
  font-weight: 400;
  font-size: 1rem;
  margin-top: 0px;
}

.imgOdd, .imgEven {
  width: 40%;
  height: 40%;
  margin: auto 0;
}

/* hr {
  border-style: none;
  border-top-style: dotted;
  height: 0px;
  border-color: grey;
  border-width: 5px;
  width: 50px;
} */

@media (width<800px) {
  .imgOdd {
    width: 50%;
    height: 50%;
  }

  .imgEven {
    width: 50%;
    height: 50%;
  }
}

@media (width<600px) {
  .eventOdd {
    flex-wrap: wrap;
    margin-top: 35px;
  }
  .imgOdd {
    width: 100%;
  }

  .eventEven {
    flex-wrap: wrap;
    margin-top: 35px;
  }
  .eventEven .txt {
    order: 2;
  }
  .eventEven .imgEven {
    width: 100%;
  }
}
@media only screen and (max-width:480px){
  .eventsContainer{
    padding: 1rem 5px;
  }
}