:root {
    --primary-color: #015fb1;
    --hover-color: #0056b3;
  }
.buttonContainer{
    display: flex;
    justify-content: space-between;
}  
.btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: larger;
  }
  .btn:hover {
    background-color: var(--hover-color);
  }