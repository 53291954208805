.galleryItem {
    position: relative;
    overflow: hidden;
    /* border: 2px solid #015fb1; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    /* background-color: #015fb1; */
  }
  
  .galleryImage {
    width: 100%;
    height: 98.5%;
    display: block;
    margin: auto;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    object-fit: contain;
    
  }
  
  .galleryItem:hover .galleryImage {
    transform: scale(1.09);
  }
  
  .imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .galleryItem:hover .imageOverlay {
    opacity: 1;
  }
  
  .imageText {
    font-size: 1.2em;
    text-align: center;
  }