@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600;700;800;900&family=Titillium+Web:wght@200;300;400;600;700&display=swap");
body, html {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-family: "Titillium Web", sans-serif;
  color: #333232;
  width: 100%;
  /* font-family: "IBM Plex Sans", sans-serif; */

  overflow-x: hidden;
  min-height: 100vh;
  /* background-color: #cfe3d8; */
  background-color: #F0F0F0;
  /* background-color: #a3c664; */
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.highlight {
  color: var(--color2);
  font-weight: 900;
  font-size: larger;
}
:root {
  --color1: #015fb1; /* For showing or highlighting content/active content */
  --color2: #4d954c; /* For showing or highlighting content/active content */
  --color3: rgb(1, 0, 11); /* For title/headings text */
  --color4: rgb(83, 83, 83); /* For descriptive text*/
  --color5: rgb(238, 238, 238); /* Use this color for shadow/shading effect */
  --mainConatinerBackground:rgba(221, 217, 217, 0.274);
  --fontSize1: 12px;
  --fontSize2: 14px;
  --fontSize3: 16px;
  --fontSize4: 18px;
  --fontSize5: 20px;
  --heading: 25px;
}
 a {
  text-decoration: none;
  font-size: 10;
  color: var(--color5);
}

a:hover {
  cursor: pointer;
}
