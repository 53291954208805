.contactus {
  /* background-color: rgb(145, 255, 0); */
  text-align: center;
}
.title h3 {
  color: var(--color3);
}
h4{
    color: var(--color1);
}
.successAlert {
  background-color: #d1e7dd;
  border: 1px solid #a9d2c0;
  border-radius: 5px;
  margin-right: 1rem;
  text-align: center;
}
.successAlert p {
  color: #3b5433;
}
.warningAlert {
  background-color: #f8d7da;
  border: 1px solid #f4c2c7;
  border-radius: 5px;
  margin-right: 1rem;
  text-align: center;
}
.warningAlert p {
  color: #951525;
}
.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: auto;
  /* border: 1px solid black; */
}
.contactdetail {
  text-align: start;
  align-content: center;
  width: 20em;
  /* border: 1px solid black; */
  padding: 1rem 1rem 1rem 2rem;
}
.contactdetail a{
    color: blue;
}
.contactform {
  text-align: start;
  width: 20em;
  padding: 1rem 1rem 1rem 2rem;
  border: 1px solid rgb(13, 94, 20);
  border-radius: 5px;
  margin: 2em;
  box-shadow: 5px 5px 5px rgb(93, 182, 93);
}
input {
  border-radius: 7px;
  margin-bottom: 1rem;
  height: 2em;
  width: 15em;
}
.submit button {
  width: 16.3rem;
  /* background-color: var(--color1);
  border-style: none; */
  background-color: #015fb1;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 4px;
}
button:hover {
    background-color: #013366;
}
@media only screen and (max-width:768px){
    .contact{
        display: flex;
        flex-direction: column;
    }
}
@media screen and (max-width:931px) {
  .banner{
    padding: 7rem 1rem 2rem;
  }
}