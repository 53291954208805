/* TalksPage.css */

.talksPage {
  text-align: start;
  background-color: white;
  /* padding-top: 11rem; */
}

/* h1,
h2 {
  color: #015fb1;
} */
.container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  margin: 5px;
}
@media only screen and (max-width:768px) {
  .container{
    flex-direction: column;
  }
  .formContainer{
    min-width: 90%;
    padding: 1rem 5rem;
  }
  .formContainer form{
    min-width: 20em ;
  }
}
/* .topicsContainer,
.formContainer {
  margin: 20px;
} */
 .topicsContainer h2{
  text-decoration: underline;
  padding-left: 5px;
 }
.topicsContainer{
  max-width: 90%;
}
.topicsContainer ul {
  list-style-type: none;
  padding: 0;
}
.topicsContainer a{
  margin-right: .5em;
}
.topicsContainer li {
  text-align: left;
  border-bottom: 1px solid #4d954c;
  padding: 10px;
  color: rgb(83, 83, 83);
}

.topicsContainer li:last-child {
  border-bottom: none;
}
.formContainer{
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: start;
  /* width: 20em; */
  padding: 1rem 1rem 1rem 1rem;
  border: 1px solid rgb(13, 94, 20);
  border-radius: 5px;
  margin: 2em;
  box-shadow: 5px 5px 5px rgb(93, 182, 93);
}
.formContainer form {
  display:flex;
  flex-direction: column;
  gap: 3px;
  min-width: 20em;
  /* margin: auto; */
}

.formContainer label {
  text-align: left;
  color: rgb(83, 83, 83);
}

.formContainer input,
.formContainer select,
.formContainer textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #4d954c;
  border-radius: 5px;
  color: rgb(83, 83, 83);
}

.formContainer textarea {
  height: 100px;
}

.formContainer button {
  background-color: #015fb1;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.formContainer button:hover {
  background-color: #013366;
}

/* Add any additional styles as needed */
@media only screen and (max-width:480px){
  .container{
    /* margin: 0px; */
    padding: .5em;
  }
  .topicsContainer{
    max-width: 100%;
  }
  .formContainer{
    max-width: 100%;
  }
}