/* Profile Component CSS Part */
.card {
    border: 2px #4d954c solid;
    border-radius: 4px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
    height: 22.5em;
    width: 14em ;
    padding: 36px 14px;
    transition: transfrom 0.2s;
    background-color: rgba(181, 204, 126, 0.466);
  }
  /* .card:hover {
    transform: scale(1.05);
    transition: 0.5s;
  } */
  .card h3 {
    color: rgb(50, 50, 50);
    margin-bottom: 3px;
  }
  .card p {
    color: rgb(83, 83, 83);
    margin: 5px 0px;
  }
  .teamConnect {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1px;
  }
  .card .pimg {
    border-radius: 50%;
    height: 9.5em;
    width: 9.5em;
    border: 2px solid #015fb1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card .pimg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .linkedinicon, .mailicon {
    width: 1.5em;
    height: 1.5em;
    color:#143b14;
  }
  .mailicon{
    width: 1.9em;
    height: 1.9em;
  }
  .linkedin,
  .mail {
    margin: 0px;
    transition: transform 0.2s;
  }
  .linkedin:hover,
  .mail:hover {
    transform: scale(1.1);
  }
  