/* Labs.css */

.labs-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  background-color: white;
  padding-top: 3rem;
}

.labs-heading {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

.labs-grid {
  display: flex;
  justify-content: center;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  gap: 1rem;
  flex-wrap: wrap;
}
.lab-item{
  display: flex;
  flex-direction: row;
  border: 1px solid #4d954c;
  border-radius: .5rem;
  padding: 1rem .4rem .5rem 1rem;
  gap: .8rem;
}
.lab-head {
  overflow: hidden;
  width: 250px;
  height: 400px;
}

.lab-image {
  /* display: block; */
  border-radius: .5rem;
  height: 9.5rem;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.lab-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lab-details span {
/* border-radius: 50%; */
cursor: pointer;
}
.pi-name{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}
.lab-name {
  font-size: 1.5em;
  margin: .3rem 0rem 0rem;
}

.lab-desc-detail {
  color: rgb(83, 83, 83);
}
