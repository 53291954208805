.cardcontainer {
  background-color: white;
  min-width: 250px;
  height: auto;
  border-radius: 14px;
  border: 2px solid var(--color2);
  box-shadow: none;
  margin: 10px;
  overflow: hidden;
}

header {
  background-image: url("../assets/profilecard/bg.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  overflow: hidden;
}

.profileimg {
  margin: auto;
  width: 100px;
  border: solid white 4px;
  border-radius: 50%;
  margin-top: 75px;
}

.boldtext1 {
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  padding: 0px 20px 0px 20px;
  color: var(--color1);
}
.boldtext {
  font-weight: bold;
  font-size: 0.95rem;
  text-align: center;
  padding: 0px 20px 5px 20px;
}

.normaltext {
  font-weight: normal;
  font-size: 0.9rem;
  color: hsl(0, 0%, 50%);
  text-align: center;
  padding-bottom: 5px;
}

.socialcontainer {
  /* background-color: red; */
  display: flex;
  /* border-top: solid rgb(206, 206, 206) 1px; */
  text-align: center;
  justify-content: center;
}

.background {
  margin-top: 11rem;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.head {
  color: var(--color1);
  margin: 0px;
  margin-top: 20px;
  font-weight: 900;
}

.titles {
  text-align: center;
  color: var(--color2);
  margin: 10px;
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.icon {
  text-align: center;
  margin: 5px;
  color: black;
}
.icon1 {
  color: hsl(0, 0%, 50%);
}

.list {
  padding-left: 30px;
}
