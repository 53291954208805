/* .newsCard {
  width: 100%;
  margin: 5px;
  border-bottom: 1px solid rgb(210, 210, 210);
}

.newsTitle {
  font-weight: 900;
  color: var(--color2);
}
.newsDesc {
  color: var(--color4);
  font-size: 12px;
}

.newsLink {
  color: var(--color1);
  font-size: 12px;
  text-decoration: none;
  text-align: right;
} */

.newsCard {
  width: 300px;
  /* height: 330px; */
  height: auto;
  border-bottom: 2px solid #4d9454;
}

.newsImage {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border: 1px solid #4d9454;
}

.newsInfo {
  font-size: 16px;
  font-weight: 600;

  margin: 10px 0;
  padding: 0 10px;
  min-height: 50px;
}

.newsButton {
  padding: 7px 10px;
  border-radius: 2px;
  background-color: #4d9454;
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

.newsButton:hover {
  background-color: #55ac5e;
}

@media screen and (max-width: 600px) {
  .newsCard {
    width: 250px;
    height: 280px;
  }

  .newsImage {
    width: 220px;
    height: 150px;
  }
}
