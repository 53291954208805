.navbar {
  width: 100%;
  /* height: 25vh; */
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.732), transparent); */

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  z-index: 10;
  /* margin-bottom: 15em; */
}
/* .navbar .active{
  background-color: rgb(1,93,173);
} */
.navbarTop {
  width: 873px;
  height: 120px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.navbarLeft {
  font-family: "poppins", sans-serif;
  display: flex;
  align-items: center;
  height: 100%;
}

.navbarRight {
  display: flex;
  flex-wrap: wrap;
  width: 120px;
  /* align-items: center; */
  /* height: 100%; */
}

.calender,
.login {
  /* width: 120px; */
  font-size: 1.2rem;
  color: white;
  font-weight: 600;
  margin-bottom: 5px;
  display: flex;
  cursor: pointer;
}

.calender:hover,
.login:hover {
  color: rgb(230, 230, 230);
}

.calender:hover span,
.login:hover span {
  color: rgb(230, 230, 230);
}

.calender span,
.login span {
  color: white;
  font-size: 1.5rem;
  margin-right: 10px;
}

@media (width <= 650px) {
  .navbarRight {
    display: none;
  }
}

.navbarLogo {
  width: 100%;
  height: 100%;
  text-shadow: 0 0 30px rgb(0, 0, 0, 0.9);

  /* margin-right: 10px; */
}

.navbarLogoText h1 {
  font-size: 4rem;

  color: white;
  font-weight: 600;
  /* text-shadow: 0 0 10px rgb(0, 0, 0, 0.9); */
  margin: 0;
  line-height: .9em;
}
.navbarLogoText p{
  font-size:medium;
  color: white;
}
.navbarLocation {
  width: 60px;
  height: 60px;
  margin-left: 10px;
}

.navbarLocationText {
  font-size: 1rem;
  color: white;
  font-weight: 600;
  /* text-shadow: 0 0 10px rgb(0, 0, 0, 0.9); */
}

.navbarBottom {
  height: 40px;
  display: flex;
  margin: 0;
  align-items: center;
  padding: 0;
}

.scrolled {
  position: fixed;
  background-color: var(--color1);
  transition: 0.4s;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.5);
  z-index: 5;
}

.navbarItem {
  position: relative;
  list-style: none;
  color: white;
  height: 40px;
  /* display: inline; */
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-shadow: 0 0 10px rgb(0, 0, 0, 0.9);
}

.navbarItem:hover {
  background-color: var(--color1);
  text-shadow: 0 0 0px rgb(0, 0, 0, 0.9);
}

/* .navbarItem:hover span {
  transform: rotate(180deg);
  transition: 0.4s;
} */

.navbarItem span {
  color: white;
  font-size: 1.5rem;
  display: flex;
}

.homeIcon {
  color: white;
  font-size: 1.7rem;
  display: flex;
}
@media screen and (max-width:930px){
  .navbar{
    display: none;
  }
}
/* @media screen and (max-width: 900px) {
  .navbarBottom {
    display: none;
  }
} */
