/* ProjectsPage.css */

.projects-page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: white;
  padding-top: 11rem;
}

.projects-heading {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.projects-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-item {
  width: 100%;
  margin-bottom: 20px;
  border: 2px solid #4d954c;
  border-radius: 8px;
  overflow: hidden;
  padding: 15px;
}

.project-name {
  font-size: 1.5em;
}

.project-description,
.project-mentor,
.project-duration {
  margin: 10px 0;
}
