.button {
  border: 2px solid var(--color2);
  box-sizing: border-box;
  padding: 8px;
  border-radius: 10px;
  background-color: white;
  transition: 0.2s;
  /* height: 40px; */
}

.button:hover {
  box-shadow: 0px 2px 5px 1px var(--color5);
  cursor: pointer;
}

.button:active {
  box-shadow: 0px 0px 5px 1px var(--color5);
  transform: translateY(3px);
}

span {
  color: var(--color2);
  font-weight: 700;
}
