/* .scholarships {
   margin-top: 0px; 
   padding-top: 0rem; 
} */
.container {
  margin: 5%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.info {
  border: 2px solid var(--color2);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 20px;
}
.compHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.compDetails {
  margin-top: 10px;  
}
.title {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 5px;
  color: var(--color1);
}
.amount,
.desc {
  font-size: 15px;
  font-weight: 700;
  color: var(--color2);
  margin-bottom: 5px;
}

.descTxt1,.descTxt2 {
  font-size: 13px;
}
.toggleIcon {
  cursor: pointer;
  font-size: 24px;
  color: #007bff; 
}

