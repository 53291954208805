.navbar {
  height: 75px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
  padding-right: 20px;
  padding-left: 0px;
  position: fixed;
  top: 0px;
  width: 100vw;
  box-shadow: 0px 2px 10px 2px var(--color5);
  z-index: 3;
}

.logoLink {
  width: 150px;
  display: flex;
  justify-content: space-evenly;
  align-self: center;
  align-items: center;
  text-decoration: none;
  color: var(--color1);
}

.logoLink h2 {
  font-size: 25px;
  line-height: .8em;
}
.logoLink p{
  font-size: xx-small;
}
.right {
  /* width: 600px; */
  width: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: .8em;
  /* justify-content: center; */
  list-style-type: none;
  padding: 0px;
  margin-left: auto;
  /* transition: 5s ease-in-out; */
  position: relative;
}
.hamburger {
  display: none;
}
.dropdown{
  position:relative;
}
.dropdown .show {
  position: absolute;
  top: 100%;  /*to show just after parent height*/
  padding-bottom: 8px;
  width: 150%;
  padding: 0 1em;
}
@media screen and (max-width: 768px) {
  .right {
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 57px;
    right: 0px;
    max-width: 70%;
    /* width: 150px; */
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);
    display: none;
    overflow-y: scroll;
    padding:0em 1.8em;
    font-size:var(--fontSize4);
  }
  .right li{
    /* padding-bottom:.4em; */
    padding-left: 0;
  }
  .expanded {
    display: block;
    box-shadow: 0px 15px 10px -15px var(--color5);
  }
  .expanded li {
    text-align: start;
    width: 100%;
    margin: 0px;
    /* padding-left: 3em; */
  }
  
  .expanded li:hover {
    background-color: var(--color5);
  }
  .hamburger {
    display: block;
  }
}

.link {
  text-decoration: none;
  color: var(--color1);
  font-size: 0.9em;
  font-weight: 600;
  /* margin-right:.5em; */
}

.link:hover {
  padding-bottom: 3px;
  border-bottom-style: solid;
  border-color: var(--color1);
  transition: 0.1s ease-in-out;
}

.logo {
  height: 50px;
}
.navbar span{
  font: size 22px;
  font-weight: bold;
}
.show{
  display: block;
  list-style: none;
  background-color: rgb(188, 243, 188);
  position: relative;
  padding-left: 1px;
  z-index: 3;
}
.show li{
  list-style: none;
  padding-left: .2em;
}
.hide{
  display: none;
}
@media screen and (min-width:931px) {
  .navbar{
    display:none ;
  }
}